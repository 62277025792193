import { WholesalePrice } from "src/redux/apiTypes";
import { LocalizeFunc } from "src/services/localization/types";
import { formatPrice } from "src/utils/product/formatPrice";

interface Params {
  wholesalePrice: WholesalePrice;
  currency: string;
  measure: string;
  bundle: number;
}

const getPriceInfo = (params: Params, localize: LocalizeFunc) => {
  const {
    wholesalePrice: {
      min_qty,
      price,
    },
    currency,
    bundle,
    measure,
  } = params;

  return [
    formatPrice(price),
    localize(`general.currency.${currency}`),
    localize('general.from'),
    min_qty,
    localize(`general.measure.${bundle && bundle > 1 ? 'bundle' : measure}`),
  ].join(' ');
};

export default getPriceInfo;
