const formatToEuropeDate = (dateStr?: string, yearFormat: 'short' | 'full' = 'short'): string => {
  if (!dateStr) {
    return '';
  }

  if (
    typeof dateStr !== 'string' ||
    !dateStr.match(/^\d{4}-\d{2}-\d{2}$/)
  ) {
    throw new Error('Wrong format of string date');
  }

  const [year, month, day] = dateStr.split('-');

  return `${ day }.${ month }.${ yearFormat === 'short' ?  year.slice(-2) : year }`;
};

export default formatToEuropeDate;
