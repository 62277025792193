import cn from 'classnames';
import useLocalization from 'src/services/localization/useLocalization';
import personalizeString from 'src/utils/cart/personalizeString';
import { WholesalePrice } from '../../../redux/apiTypes';
import styles from './WholesalePrices.styles';
import WholesalePricesShort from './WholesalePricesShort';
import getPriceInfo from './getPriceInfo';

export interface WholesalePricesProps {
  wholesalePrices: WholesalePrice[];
  currency: string;
  measure: string;
  bundle: number;
  isBig?: boolean;
  isSmall?: boolean;
  withDate?: boolean;
}

const WholesalePrices = (props: WholesalePricesProps) => {
  const { wholesalePrices, currency, measure, isBig, isSmall, bundle, withDate } = props;
  const localize = useLocalization();
  const wholesalePricesLength = wholesalePrices.length;

  if (wholesalePricesLength === 0) {
    return null;
  }

  if (wholesalePricesLength > 1 && !withDate) {
    return (
      <WholesalePricesShort
        wholesalePrices={wholesalePrices}
        measure={measure}
        currency={currency}
        bundle={bundle}
      />
    );
  }

  const dueDateIsVisible = wholesalePricesLength > 1 && withDate && wholesalePrices[0].due_date;

  return (
    <div className='WholesalePrices'>
      {dueDateIsVisible && (
        <div className='WholesalePrices__dueDate' data-marker='PriceWholesale_due_date'>
          {personalizeString(
            localize('prices_are_available_until_text'),
            [
              new Date(wholesalePrices[0].due_date).toLocaleDateString('ru-RU'),
            ],
          )}
        </div>
      )}

      <ul
        className={cn(
          'WholesalePrices__list', {
          'WholesalePrices__listBig': isBig,
          'WholesalePrices__listSmall': isSmall,
        })}
      >
        {
          wholesalePrices.map((item, index) => {
            const { due_date } = item;

            const dateStr = wholesalePricesLength === 1 && due_date && isBig
              ? ` ${localize('general.to').toLocaleLowerCase()} ${new Date(due_date).toLocaleDateString('ru-RU')}`
              : '';

            const priceInfo = getPriceInfo(
              {
                wholesalePrice: item,
                bundle,
                measure,
                currency,
              },
              localize,
            );

            return (
              <li
                key={index}
                data-marker={`PriceWholesale_${index}`}
              >
                {`${priceInfo}${dateStr}`}
              </li>
            );
          },
        )}
      </ul>
      <style jsx>{styles}</style>
    </div>
  );
};

WholesalePrices.defaultProps = {
  wholesalePrices: [],
  currency: '',
  measure: '',
  isBig: false,
  isSmall: false,
  bundle: 1,
};

export default WholesalePrices;
