import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
    .DiscountDisclaimer_subhead {
      ${typography.h6}
      line-height: 22px;
    }
    .DiscountDisclaimer_productTile {
      ${typography.text}
      line-height: 17px;
      font-size: 13px;
      display: inline-block;
      padding-left: 4px;
    }
    .DiscountDisclaimer {
      color: var(--colors-red);
    }
    @media ${phone} {
      .DiscountDisclaimer_productTile {
        margin-right: -1px;
      }
    }
  `;
