import * as React from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import formatToEuropeDate from 'src/utils/date/formatToEuropeDate';
import { styles } from './DiscountDisclaimer.styles';

export type DisclaimerVariant = 'subhead' | 'productTile'

interface Props {
  dueDate: string
  variant?: DisclaimerVariant
}

const DiscountDisclaimer: React.FunctionComponent<Props> = props => {
  const localize = useLocalization();
  const { dueDate, variant } = props;

  let dateLabel = `${localize('discount_available_when')} ${formatToEuropeDate(dueDate, 'full')}`;

  if (variant === 'productTile') {
    const [, month, day] = dueDate.split('-');
    dateLabel = ` ${localize('general_till')} ${day}.${month}`;
  }

  return (
    <span className={`DiscountDisclaimer DiscountDisclaimer_${variant}`}
      data-marker='Promotion_until_date'
    >
      {dateLabel}
      <style jsx>{styles}</style>
    </span>
  );
};

export default DiscountDisclaimer;
