import useLocalization from 'src/services/localization/useLocalization';
import typography from 'src/styles/typography';
import { WholesalePrice } from '../../../redux/apiTypes';
import getPriceInfo from './getPriceInfo';

export interface WholesalePricesShortProps {
  wholesalePrices: WholesalePrice[];
  currency: string;
  measure: string;
  bundle: number;
}

const WholesalePricesShort = (props: WholesalePricesShortProps) => {
  const { wholesalePrices, ...restProps } = props;
  const localize = useLocalization();

  const priceInfo = getPriceInfo(
    {
      wholesalePrice: wholesalePrices[0],
      ...restProps,
    },
    localize,
  );

  return (
    <div className='WholesalePricesShort'>
      {`${priceInfo} ...`}
      <style jsx>{`
          .WholesalePricesShort {
            ${typography.text}
            color: var(--colors-doveGray);
          }
      `}</style>
    </div>
  );
};

export default WholesalePricesShort;
